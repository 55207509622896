import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import Button from '@youship/components/objects/button';
import Img from 'components/image';

import './styles.scss';

const Banner = ({ buttons, image, text, title }) => (
  <div className="banner">
    <div className="banner__image-container d-none d-sm-block">
      {!!image && (
        <Img
          alt="Banner Background"
          classNames="banner__image"
          src={image}
        />
      )}
    </div>
    <div className="container">
      <div className="row">
        <div className="col col-12 col-sm-10 col-md-6 col-lg-5">
          {!!title && (
            <h2 className="h2--display banner__title">
              <FormattedMessage id={title} />
            </h2>
          )}
          {!!text && (
            <div className="banner__text">
              <FormattedMessage id={text} />
            </div>
          )}
          {Array.isArray(buttons) && buttons.map(({ text: buttonText, ...buttonProps }, index) => (
            <Button
              {...buttonProps}
              key={index}
              classNames="banner__button"
            >
              <FormattedMessage id={buttonText} />
            </Button>
          ))}
        </div>
      </div>
    </div>
  </div>
);

Banner.propTypes = {
  buttons: PropTypes.arrayOf(PropTypes.shape({
    messageId: PropTypes.string,
    text: PropTypes.string
  })),
  image: PropTypes.string,
  text: PropTypes.string,
  textMessageId: PropTypes.string,
  title: PropTypes.string,
  titleMessageId: PropTypes.string
};

Banner.defaultProps = {
  buttons: null,
  image: null,
  text: null,
  textMessageId: null,
  title: null,
  titleMessageId: null
};

export default Banner;
